import React from "react"
import Page from '../../../sb-components/page'
import Layout from "../../../sb-components/layout"
import StoryblokService from '../../../utils/storyblok-service'

class MediaArticlePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      story: {
        content: this.props.data?.story
          ? JSON.parse(this.props.data.story.content)
          : {}
      }
    };
  }

  async componentDidMount() {
    const story = await this.getInitialStory();
    if (story.content) {
      this.setState({ story })
    }

    setTimeout(() => StoryblokService.initEditor(this), 200)
  };

  async getInitialStory() {
    StoryblokService.setQuery(this.props.location.search);

    const { data: { story } } = await StoryblokService.get(`cdn/stories/${this.props.location.pathname}`);
    return story
  };

  render() {
    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <Layout
        className="page page-education page-media page-media-article"
        full_slug={this.state.story.full_slug}
      >
        <Page blok={{ ...this.state.story.content, title: this.state.story.content || "title" }} />
      </Layout>
    )
  }
}
export default MediaArticlePage;
